import { useEffect, useState } from 'react';

export const useIsVisible = ({
  scrollRef,
  delay = 0,
}: {
  scrollRef: React.MutableRefObject<HTMLDivElement | null>;
  delay?: number;
}) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    let observer: IntersectionObserver;
    setTimeout(() => {
      observer = new IntersectionObserver(([entry]) => {
        setIntersecting(entry.intersectionRatio > 0);
      });

      if (scrollRef.current) {
        observer.observe(scrollRef.current);
      }
    }, delay);

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [delay, scrollRef]);

  return isIntersecting;
};
